import React, { useEffect, useState } from "react";
import ReactPixel from "react-facebook-pixel";
import { v4 as uuidv4 } from "uuid";
import { Route, RouteComponentProps, Switch, useLocation } from "react-router-dom";
import Loader from "react-loader-spinner";
import { Transition } from "react-transition-group";

import { ReactComponent as ArrowIcon } from "../../images/steps_arrow.svg";
import Steps from "./components/steps";
import Question from "./components/question";
// import Clip from "./components/clip";
import Success from "./components/success";
import PollingPageWelcome from "./components/welcome";
import { CornerButton } from "../common/cornerButton";
import {
  CampaignQuestion,
  CampaignQuestionKinds,
  CampaignResources,
  CampaignResourcesScoreThreshold,
  getCampaignResources,
  internalAnswerLog,
  internalUuidLog,
  sendCampaignAnswer,
  sendCampaignOpenAnswer,
} from "../../api";
import { MODAL_TYPE, useWebinar } from "../../helpers/webinarContext";
import { translate } from "../../translations";

import "./polling.scss";

const USER_POLL_UUID_KEY = "user-poll-uuid";
const USER_POLL_SCORE_KEY = "user-poll-score";
const QUICK_SELECTION = true;
const TRANSITION_TIME = 300;

const getFingerprintHash = async () => {
  var canvas = document.createElement("canvas");
  var ctx = canvas.getContext("2d");
  var txt = "i9asdm..$#po((^@KbXrww!~cz\n\r <🍏🍎🍐🍊🍋🍌🍉🍇🍓🍈🍒🍑🍍🥝>";
  canvas.height = 200;
  canvas.width = 500;

  if (ctx) {
    ctx.textBaseline = "top";
    ctx.font = "14px 'Arial'";
    ctx.textBaseline = "alphabetic";
    ctx.fillStyle = "#f60";
    ctx.fillRect(125,1,62,20);
    // Some tricks for color mixing to increase the difference in rendering
    ctx.fillStyle = "#069";
    ctx.fillText(txt, 2, 15);
    ctx.fillStyle = "rgba(102, 204, 0, 0.7)";
    ctx.fillText(txt, 4, 17);
    ctx.globalCompositeOperation = "multiply";
    ctx.fillStyle = "rgb(255,0,255)";
    ctx.beginPath();
    ctx.arc(50, 50, 50, 0, Math.PI * 2, true);
    ctx.closePath();
    ctx.fill();
    ctx.fillStyle = "rgb(0,255,255)";
    ctx.beginPath();
    ctx.arc(100, 50, 50, 0, Math.PI * 2, true);
    ctx.closePath();
    ctx.fill();
    ctx.fillStyle = "rgb(255,255,0)";
    ctx.beginPath();
    ctx.arc(75, 100, 50, 0, Math.PI * 2, true);
    ctx.closePath();
    ctx.fill();
    ctx.fillStyle = "rgb(255,0,255)";
    ctx.arc(75, 75, 75, 0, Math.PI * 2, true);
    ctx.arc(75, 75, 25, 0, Math.PI * 2, true);
    ctx.fill("evenodd");

    document.body.appendChild(canvas);

    // Get the canvas data URL
    const dataURL = canvas.toDataURL();

   try {
     // Create a hash of the data URL
     const crypto = window.crypto || (window as any).msCrypto;
     const buffer = new TextEncoder().encode(dataURL);
     const hashBuffer = await crypto.subtle.digest("SHA-256", buffer);
 
     const hashArray = Array.from(new Uint8Array(hashBuffer));
     const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
 
     return hashHex;
   } catch (_error) {
    return null;
   }
  }
  return null;
}


const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export interface Answers {
  [questionId: number]: (number | string)[];
}

interface RouteParams {
  pollingId: string;
}

export interface PollingProps {
  questions: CampaignQuestion[];
  campaignId?: string;
  campaignTitle?: string | null;
  finalCallToActionText?: string | null;
  finalCallToActionLink?: string | null;
  finalCaption?: string | null;
  thankYouMsg?: string | null;
  scoreThreshold?: CampaignResourcesScoreThreshold[];
}

const Polling = ({
  questions: initialQuestions = [],
  campaignId,
  campaignTitle,
  finalCallToActionText,
  finalCallToActionLink,
  finalCaption,
  thankYouMsg,
  scoreThreshold
}: RouteComponentProps<RouteParams> & PollingProps) => {
  const [answers, setAnswers] = useState<Answers>({});
  const [activeStep, setActiveStep] = useState(0);
  const [switchingQuestion, setSwitchingQuestion] = useState(false);
  const [formScore, setFormScore] = useState<string | null>(null);
  const [questions, setQuestions] = useState<CampaignQuestion[]>(initialQuestions);
  const { setActiveModal } = useWebinar();
  const queryParams = useQuery();
  let scoreMessage: string | null = null;

  if(formScore) {
    const sortedScoreThreshold = scoreThreshold?.sort((a,b)=> Number(b?.attributes?.threshold_value) - Number(a?.attributes?.threshold_value));
    const foundScoreThreshold = sortedScoreThreshold?.find((_scoreThreshold: CampaignResourcesScoreThreshold) => Number(_scoreThreshold?.attributes?.threshold_value) <= Number(formScore));
    if(foundScoreThreshold) {
      scoreMessage = foundScoreThreshold?.attributes?.message_content;
    }
  }

  useEffect(() => {
    const savedScore = localStorage.getItem(USER_POLL_SCORE_KEY)
    if(savedScore) {
      setFormScore(savedScore);
    }

    // prepopulate answers
    const prepopulatedAnswers: { question: number, answer: number }[] = [];
    const _questions = initialQuestions.filter((question) => {
      const preselectedAnswerId = queryParams.get(`question_${question.id}`);
      if(preselectedAnswerId) {
        prepopulatedAnswers.push({ question: question.id, answer: Number(preselectedAnswerId)});
        return false;
      }
      return true;
    });

    sendPrepopulatedQuestions(prepopulatedAnswers);

    setQuestions(_questions);
  }, []);

  const sendPrepopulatedQuestions = async (answers: { question: number, answer: number }[]) => {
    const _answers = answers;

    try {
      // recursion fn, making a queue for prepopulated answers
      const sendPrepopulatedAnswer = async () => {
        const answer = _answers[0];

        if(answer) {
          await sendAnswer(answer.question, answer.answer);
          _answers.shift();
          await sendPrepopulatedAnswer();
        }
      }
      await sendPrepopulatedAnswer();

    } catch (e) {
      console.error(e);
    }
  }

  const goToStep = (step: number, isNext?: boolean) => {
    if(isNext) {
      const activeQuestion = questions[activeStep];
      onSendAnswer(activeQuestion.id, answers[activeQuestion.id][0], activeQuestion.kind);
    } else {
      // transition to prev question
      setSwitchingQuestion(true);
      setTimeout(() => {
        setSwitchingQuestion(false);
      }, 0);
      setTimeout(() => {
        setActiveStep(step);
      }, TRANSITION_TIME);
    }
  };

  const getNewUuid = () => {
    const currentUuid = localStorage.getItem(USER_POLL_UUID_KEY);

    if (!currentUuid) {
      const newUuid = uuidv4();
      localStorage.setItem(USER_POLL_UUID_KEY, newUuid);
      return newUuid;
    }

    return currentUuid;
  };

  const sendAnswer = async (questionId: number, answerId: number) => {
    try {
      const userPollUuid =
        localStorage.getItem(USER_POLL_UUID_KEY) || getNewUuid();
      if (campaignId) {
        ReactPixel.trackCustom("FilledForm", {
          type: "campaign",
          id: campaignId,
          answer_ids: [answerId],
        });
        const response = await sendCampaignAnswer(
          userPollUuid,
          campaignId,
          questionId,
          answerId
        );
        await internalAnswerLog(userPollUuid, answerId);
        if(response.score) {
          setFormScore(response.score)
          localStorage.setItem(USER_POLL_SCORE_KEY, response.score)
        }
      }
    } catch (e) {
      console.error("Send answer error", answerId, campaignId, e);
    }
  };

  const sendOpenAnswer = async (questionId: number, answerId: string) => {
    try {
      const userPollUuid =
        localStorage.getItem(USER_POLL_UUID_KEY) || getNewUuid();
      if (campaignId) {
        ReactPixel.trackCustom("FilledForm", {
          type: "campaign",
          id: campaignId,
          answer_ids: [answerId],
        });
        await sendCampaignOpenAnswer(
          userPollUuid,
          campaignId,
          questionId,
          answerId
        );
        await internalAnswerLog(userPollUuid, answerId);
      }
    } catch (e) {
      console.error("Send answer error", answerId, campaignId, e);
    }
  };

  const onClickAnswer = (
    questionId: number,
    answerId: number | string,
    kind?: CampaignQuestionKinds | null,
  ) => {
    const newAnswer = {
      [questionId]: [answerId],
    };
    setAnswers({
      ...answers,
      ...newAnswer,
    });

    if (kind === CampaignQuestionKinds.INPUT ? false : QUICK_SELECTION) {
      onSendAnswer(questionId, answerId, kind);
    }
  };

  const onSendAnswer = (
      questionId: number,
      answerId: number | string,
      kind?: CampaignQuestionKinds | null
  ) => {
    if(kind === CampaignQuestionKinds.INPUT) {
      sendOpenAnswer(questionId, answerId as string);
    } else {
      sendAnswer(questionId, answerId as number);
    }

    // transition to next question
    setSwitchingQuestion(true);
    setTimeout(() => {
      setSwitchingQuestion(false);
    }, 0);
    setTimeout(() => {
      setActiveStep(activeStep + 1);
    }, TRANSITION_TIME);
  }

  const renderContent = () => {
    const isFinished = activeStep >= questions.length;

    if (isFinished) {
      return <Success
          callToActionText={finalCallToActionText}
          callToActionLink={finalCallToActionLink}
          caption={finalCaption}
          thankYouMsg={thankYouMsg}
          scoreMessage={scoreMessage}
      />;
    }

    const question = questions?.[activeStep];

    const defaultStyle = {
      transition: `opacity ${TRANSITION_TIME}ms ease-out`,
      opacity: 1,
    };
    const transitionStyles = {
      entering: { opacity: 0 },
      entered: { opacity: 1 },
      exiting: { opacity: 1 },
      exited: { opacity: 0 },
    };

    return (
      question && (
        <Transition
          in={!switchingQuestion}
          timeout={{
            appear: TRANSITION_TIME,
            enter: TRANSITION_TIME,
            exit: TRANSITION_TIME,
          }}
        >
          {(state) => (
            <div
              style={{
                ...defaultStyle,
                // @ts-ignore
                ...transitionStyles[state],
              }}
            >
              <Question
                key={question?.id}
                subtitle={question?.header || campaignTitle}
                id={question?.id}
                title={question?.content}
                answers={(question?.answers || []).map((answer) => ({
                  ...answer,
                  selected: (answers?.[question?.id] || []).includes(answer.id),
                }))}
                kind={question?.kind}
                openQuestionSelectedValue={answers?.[question?.id]?.[0] as string || ''}
                onClickAnswer={onClickAnswer}
                quickSelection={question?.kind === CampaignQuestionKinds.INPUT ? false : QUICK_SELECTION}
              />
            </div>
          )}
        </Transition>
      )
    );
    // <Clip
    //     title="Watch THIS CLIP AND PREPARE TO ANSWER
    //     THE QUESTION THAT FOLLOWS:"
    //     id={1}
    //     url="/demo.MP4"
    //     // url="https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4"
    // />
  };

  const question = questions?.[activeStep];
  const prevHidden = activeStep === 0;
  const isFinished = activeStep >= questions.length;
  const canGoToNextStep =
    answers?.[question?.id] && answers?.[question?.id].length > 0;
  const shouldShowFinishButton = activeStep + 1 >= questions.length;
  const shouldShowPrivacyButton = process.env.REACT_APP_LANGUAGE !== 'ru' && activeStep === 0;
  const shouldHideNextButton = question?.kind === CampaignQuestionKinds.INPUT ? false : QUICK_SELECTION;

  return (
    <section className="polling">
      <div className="polling__content">
        <div className="polling__steps">
          <Steps active={activeStep} size={(questions || []).length} />
        </div>

        <div className="polling__step container-l">{renderContent()}</div>
        {!isFinished && (
            <div className={`polling__footer container-l ${
              shouldShowPrivacyButton ? 'polling__footer--with-privacy-btn' : ''
            }`}>
              <div
                className={`polling__footer-button ${
                  prevHidden ? "polling__footer-button--hidden" : ""
                }`}
                onClick={() => {
                  // remove last answer when moving back to the quick-selection question
                  const prevQuestion = questions?.[activeStep - 1];
                  if(QUICK_SELECTION && prevQuestion?.kind === CampaignQuestionKinds.RADIO) {
                    const newAnswers = answers;
                    delete newAnswers[prevQuestion.id];
                    setAnswers({
                      ...newAnswers
                    })
                  }
                  return !prevHidden && goToStep(activeStep - 1)
                }}
              >
                <ArrowIcon />
                <span>{translate('polling.prev')}</span>
              </div>
              {!shouldHideNextButton && (
                  <div
                      className={`polling__footer-button ${
                          !canGoToNextStep ? "polling__footer-button--disabled" : ""
                      }`}
                      onClick={() =>
                          canGoToNextStep && goToStep(activeStep + 1, true)
                      }
                  >
                    <span>{shouldShowFinishButton ? translate('polling.finish') : translate('polling.next')}</span>
                    <ArrowIcon />
                  </div>
              )}
            </div>
          )}
      </div>

      {shouldShowPrivacyButton && (
        <CornerButton
          text="Privacy Policy"
          onClick={() => setActiveModal(MODAL_TYPE.PRIVACY)}
        />
      )}
    </section>
  );
};

export const PollingPage = (props: RouteComponentProps<RouteParams>) => {
  const pollingId = props.match.params.pollingId;
  const [isLoading, setIsLoading] = useState(false);
  const [campaign, setCampaign] = useState<CampaignResources | undefined>(
    undefined
  );
  const [scoreThreshold, setCampaignScoreThreshold] = useState<CampaignResourcesScoreThreshold[] | undefined>(
    undefined
  );

  useEffect(() => {
    let userPollUuid = localStorage.getItem(USER_POLL_UUID_KEY);
    if (!userPollUuid) {
      const uuid = uuidv4();
      localStorage.setItem(USER_POLL_UUID_KEY, uuid);
      userPollUuid = uuid;
    }

    if (pollingId) {
      const requestCampaignResources = async () => {
        try {
          setIsLoading(true);
          const campaignResources = await getCampaignResources(
            pollingId,
            userPollUuid
          );
          const fingerprintHash = await getFingerprintHash();
          await internalUuidLog(pollingId, userPollUuid, fingerprintHash);

          const data = campaignResources.data;
          setCampaign(data);
          setCampaignScoreThreshold(campaignResources.included);
          setIsLoading(false);
        } catch (e) {
          setIsLoading(false);
        }
      };
      requestCampaignResources();
    }
  }, []);

  useEffect(() => {
    const PIXEL_ID = campaign?.attributes?.pixel_id;
    if (PIXEL_ID) {
      ReactPixel.init(PIXEL_ID, undefined, {
        autoConfig: true,
        debug: false,
      });
      ReactPixel.pageView();
    }
  }, [campaign?.attributes?.pixel_id]);

  if (isLoading || !campaign) {
    return (
      <div className="loader-wrapper">
        <Loader type="TailSpin" color="#000" height={50} width={50} />
      </div>
    );
  }

  return (
    <Switch>
      <Route
        exact
        path="/polling/:pollingId"
        render={(props) => (
          <PollingPageWelcome {...props} campaign={campaign} />
        )}
      />
      <Route
        exact
        path="/polling/:pollingId/start"
        render={(props) => (
          <Polling
            {...props}
            questions={
              campaign?.attributes?.inline_questions_with_answers || []
            }
            campaignId={campaign?.id}
            campaignTitle={campaign?.attributes.welcoming_title}
            finalCallToActionText={campaign?.attributes.after_survey_call_to_action}
            finalCallToActionLink={campaign?.attributes.after_survey_redirection_link}
            finalCaption={campaign?.attributes.after_survey_caption}
            thankYouMsg={campaign?.attributes.thank_you_message}
            scoreThreshold={scoreThreshold}
          />
        )}
      />
    </Switch>
  );
};
